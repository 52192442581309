import ApiService from "./api.service";

const PatientService = {
  async getPatients(pagination) {
    return await ApiService.commonGETRequest(
      "/patient/list/",
      JSON.parse(JSON.stringify(pagination))
    );
  },
  async getPatient() {
    return await ApiService.commonGETRequest("/patient/");
  },
  async getPatientCard(sessionId, patient, owner) {
    return await ApiService.commonGETRequest(`/patient/session/${sessionId}/`, {
      patient_id: patient,
      owner: owner,
    });
  },
  async getPatientCards(cardType, patient, owner) {
    return await ApiService.commonGETRequest("/patient/session/", {
      patient_id: patient,
      type: cardType,
      owner: owner,
    });
  },
  async getSessionInfo(patient, owner) {
    return await ApiService.commonGETRequest("/patient/session/info/", {
      patient_id: patient,
      owner: owner,
    });
  },
  async getSessionInfoList(patient) {
    return await ApiService.commonGETRequest("/patient/session/info/list/", {
      patient_id: patient,
    });
  },
  async editPatient(patient) {
    return await ApiService.commonPUTRequest("/patient/", {}, patient);
  },
  async editPatientCard(card) {
    return await ApiService.commonPUTRequest(
      `/patient/session/${card.id}/`,
      {},
      card
    );
  },
  async finishSessionAppointment(card) {
    return await ApiService.commonPOSTRequest(
      `/patient/session/${card.id}/appointment/finish/`,
      {},
      card
    );
  },
  async changePatientCard(sessionId, card, action) {
    return await ApiService.commonPOSTRequest(
      `/patient/session/${sessionId}/`,
      {},
      {
        session: card,
        type: action,
      }
    );
  },
  async generatePatientCard(params, patient, owner) {
    return await ApiService.commonPOSTRequest(
      "/print/card/",
      { owner, patient_id: patient },
      params
    );
  },
  async checkGeneratedPatientCardStatus(uuid) {
    return await ApiService.commonGETRequest(`/print/${uuid}/card/check/`);
  },
  async generateRecommendations(params, patient, owner) {
    return await ApiService.commonPOSTRequest(
      "/print/recommendations/",
      { owner, patient_id: patient },
      params
    );
  },
  async checkGeneratedRecommendationsStatus(uuid) {
    return await ApiService.commonGETRequest(
      `/print/${uuid}/recommendations/check/`
    );
  },
  async getHistoryField(args) {
    let tmpOptions = args.options
      ? JSON.parse(JSON.stringify(args.options))
      : {};
    tmpOptions.session = args.session;
    tmpOptions.type = args.type !== undefined ? args.type : 1;
    tmpOptions.field = args.field !== undefined ? args.field : 0;
    tmpOptions.patient_id = args.patient;
    tmpOptions.owner = args.owner;
    return await ApiService.commonGETRequest(
      `/patient/historyfield/${args.name}/`,
      tmpOptions
    );
  },
  async updateHistoryField(name, field) {
    return await ApiService.commonPUTRequest(
      `/patient/historyfield/${name}/`,
      {},
      field
    );
  },
  async removeDrawingFromHistoryField(name, field) {
    return await ApiService.commonPATCHRequest(
      `/patient/historyfield/${name}/`,
      {},
      field
    );
  },
  async appendHistoryField(patient, field, value) {
    return await ApiService.commonPOSTRequest(
      `/patient/historyfield/${field.name}/`,
      {
        patient_id: patient,
      },
      {
        field: field,
        data: value,
      }
    );
  },
  async uploadDocuments(form, upFunc, canFunc) {
    return await ApiService.commonPOSTRequest(
      "/patient/document/",
      {},
      form,
      {
        "Content-Type": "multipart/form-data",
      },
      upFunc,
      canFunc
    );
  },
  async updateDocument(docId, form, upFunc, canFunc) {
    return await ApiService.commonPATCHRequest(
      `/patient/document/${docId}/`,
      {},
      form,
      {
        "Content-Type": "multipart/form-data",
      },
      upFunc,
      canFunc
    );
  },
  async getDocuments(session) {
    return await ApiService.commonGETRequest("/patient/document/", {
      session,
    });
  },
  async removeDocument(documentId) {
    return await ApiService.commonDELETERequest(
      `patient/document/${documentId}/`
    );
  },
  async downloadDocument(documentUrl) {
    return await ApiService.commonGETRequest(
      documentUrl,
      {},
      "arraybuffer",
      false
    );
  },
  async addDuplication(patient, session, model, text) {
    return await ApiService.commonPOSTRequest(
      `/patient/${patient.id}/session/${session.id}/duplicate/`,
      {},
      { model, text }
    );
  },
  async updateDuplication(patient, session, model, text) {
    return await ApiService.commonPUTRequest(
      `/patient/${patient.id}/session/${session.id}/duplicate/`,
      {},
      { model, text }
    );
  },
  async destroyDuplication(patient, session, model) {
    return await ApiService.commonDELETERequest(
      `/patient/${patient.id}/session/${session.id}/duplicate/`,
      { model }
    );
  },
  async getDocumentsCount() {
    return await ApiService.commonGETRequest("/patient/document/count/");
  },
  async cloneFields(patient, session, group) {
    return await ApiService.commonPOSTRequest(
      `patient/${patient}/session/${session.id}/clone/`,
      {},
      {
        session,
        fields_type: group,
      }
    );
  },
  async savedSessionAppointmentList(patient, session) {
    return await ApiService.commonGETRequest(
      `/patient/${patient.id}/session/${session.id}/appointments/`
    );
  },
  async getRecommendations(codes) {
    return await ApiService.commonGETRequest("/recommendations/");
  },
};

export default PatientService;
