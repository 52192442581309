<script>
import ModelLocalStorage from "@/store/local/model";

export default {
  timers: {
    emitSearch: { time: 850 },
  },
  data() {
    return {
      totalItems: 0,
      objects: [],
      pagination: {
        rowsPerPage: 10,
        page: 1,
        search: "",
        sortBy: undefined,
        descending: undefined,
      },
      savePagination: false,
      storageKey: "",
      search: null,
      defaultSortBy: {
        default: undefined,
      },
      defaultDescending: {
        default: undefined,
      },
    };
  },
  computed: {
    totalPages() {
      if (this.totalItems == null || this.pagination.rowsPerPage == null)
        return 1;

      var tmp = Math.ceil(this.totalItems / this.pagination.rowsPerPage);
      if (tmp <= 0) return 1;
      return tmp;
    },
  },
  methods: {
    fetchData() {},
    validatePagination() {
      if (this.pagination.page > this.totalPages) {
        this.pagination.page = 1;
      }
    },
    saveStoragePagination() {
      if (!this.storageKey) {
        return;
      }
      ModelLocalStorage.save(
        `${this.storageKey}_paginator`,
        JSON.stringify(this.pagination)
      );
    },
    restoreStoragePagination() {
      if (!this.storageKey) {
        return;
      }
      const storagePagination = ModelLocalStorage.get(
        `${this.storageKey}_paginator`,
        null
      );
      if (storagePagination) {
        this.pagination = JSON.parse(storagePagination);
        this.search = this.pagination.search;
      }
    },
    resetPaginationPatrial() {
      this.$set(this.pagination, "search", "");
      this.$set(this.pagination, "page", 1);
      this.search = "";
    },
    resetPaginationFull() {
      this.pagination = {
        rowsPerPage: 10,
        page: 1,
        search: "",
        sortBy: this.defaultSortBy,
        descending: this.defaultDescending,
      };
      this.search = "";
    },
    emitSearch() {
      if (this.pagination.search != this.search) {
        this.$set(this.pagination, "search", this.search);
        this.$set(this.pagination, "page", 1);
      }
    },
  },
  watch: {
    pagination: {
      handler() {
        this.saveStoragePagination();
        this.fetchData();
      },
      deep: true,
    },
    search() {
      if (
        this.search !== this.pagination.search &&
        (this.search == null ||
          this.search.length >= 3 ||
          this.search.length == 0)
      ) {
        this.$timer.stop("emitSearch");
        this.$timer.start("emitSearch");
      }
    },
  },
  mounted() {
    this.restoreStoragePagination();
  },
  created() {
    this.restoreStoragePagination();
    this.fetchData();
  },
};
</script>